import IBillingInformation from '../model/IBillingInformation'
import { administration } from './support'

const BASE_CRED_URL = 'iam/credit'
const IAM = 4

export interface IAddCreditsData {
  credits: number
  billingReference: IBillingInformation
  salesOrderId: string
  expiresAt: string
  tenantId: string
}

export interface ITenantCreditsDetailsInput {
  statusFilter: string[]
  offset: number
  limit: number
  tenantId: string
}

export interface IMoveCreditsInput {
  credits: number
  sourceTenantId: string | null
  targetTenantId: string | null // Nechat volny, null, podle toho kam to presunu
}

export interface IReserveOrSpendCreditsInput {
  credits: number
  tenantId: string
  operationId: string
  projectId: string
}

export interface IBillingReferenceCreditsDetailsInput {
  statusFilter: string[]
  offset: number
  limit: number
  billingReference?: IBillingInformation
  billingInformation?: IBillingInformation
}

export const postApiCredit = async (data: IAddCreditsData) => {
  try {
    const response = await administration.postJson(BASE_CRED_URL, data, IAM)
    return response
  } catch (_error) {
    return []
  }
}

export const postApiCreditDetails = async (
  data: ITenantCreditsDetailsInput
) => {
  try {
    const response = await administration.postJson(
      BASE_CRED_URL + '/details',
      data,
      IAM
    )
    return response
  } catch (_error) {
    return []
  }
}

export const postApiCreditDetailsExtended = async (
  data: ITenantCreditsDetailsInput
) => {
  try {
    const response = await administration.postJson(
      BASE_CRED_URL + '/details/extended',
      data,
      IAM
    )
    return response
  } catch (_error) {
    return []
  }
}

export const putApiCreditMove = async (data: IMoveCreditsInput) => {
  try {
    const response = await administration.putJson(
      BASE_CRED_URL + '/move',
      data,
      IAM
    )
    return response
  } catch (_error) {
    return []
  }
}

export const putApiCreditReserve = async (
  data: IReserveOrSpendCreditsInput
) => {
  try {
    const response = await administration.putJson(
      BASE_CRED_URL + '/reserve',
      data,
      IAM
    )
    return response
  } catch (_error) {
    return []
  }
}

export const putApiCreditSpend = async (data: IReserveOrSpendCreditsInput) => {
  try {
    const response = await administration.putJson(
      BASE_CRED_URL + '/spend',
      data,
      IAM
    )
    return response
  } catch (_error) {
    return []
  }
}

export const postApiCreditUnassigned = async (
  data: IBillingInformation,
  filter?: string[] | null
) => {
  try {
    const query = filter ? `?filter=${filter}` : ''
    const response = await administration.postJson(
      BASE_CRED_URL + `/unassigned${query}`,
      data,
      4
    )
    return response
  } catch (_error) {
    return []
  }
}

export const postApiCreditUnassignedDetails = async (
  data: IBillingReferenceCreditsDetailsInput
) => {
  try {
    const response = await administration.postJson(
      BASE_CRED_URL + '/unassigned/details',
      data,
      IAM
    )
    return response
  } catch (_error) {
    return []
  }
}

export const getApiCredit = async (
  tenantId: string,
  filter?: string[] | null
) => {
  try {
    const query = filter ? `?filter=${filter}` : ''
    const response = await administration.getJson(
      BASE_CRED_URL + `/${tenantId}${query}`,
      IAM
    )
    return response.data
  } catch (_error) {
    return []
  }
}
