import React, { useEffect, useState } from 'react'
import objectHash from 'object-hash'
import { Form, Formik } from 'formik'
import { Grid } from '@material-ui/core'
import TextField from '../formik/TextField'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import messages from '../../shared/messages'
import { MikeButton } from '@mike/mike-shared-frontend'
import { css } from 'emotion'
import {
  getProject,
  IBillingInformation,
  ICreateTenantFormValues
} from '../../apis/admin'
import { useTypedSelector } from '../../reducers'

const submitButtonStyle = css`
  float: right;
`
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required')
    .min(3, 'Too short')
    .max(50, 'Too long')
    .matches(
      /^[a-zA-Z0-9\s]*$/,
      'Only letters, numbers and spaces are allowed'
    ),
  billingReference: Yup.string().required('Required'),
  billingReferenceType: Yup.string().required('Required'),
  billingReferenceTag: Yup.string()
})

interface IProps {
  handleCreateTenant: (values: ICreateTenantFormValues) => void
}

const CreateTenantForm = ({ handleCreateTenant }: IProps) => {
  const [billingInformation, setBillingInformation] = useState<
    IBillingInformation
  >({
    billingReference: '',
    billingReferenceTag: '',
    billingReferenceType: ''
  })
  const intl = useIntl()
  const tenantId = useTypedSelector(state => state['auth'].user?.tenantId) || ''

  const defaultInitialValues: ICreateTenantFormValues = {
    name: '',
    billingReference: billingInformation.billingReference,
    billingReferenceType: billingInformation.billingReferenceType as
      | 'Maconomy'
      | 'DHI',
    billingReferenceTag: billingInformation.billingReferenceTag
  }

  useEffect(() => {
    const fetchBillingInformation = async () => {
      const project = await getProject(tenantId)
      setBillingInformation(project.billingInformation)
    }

    fetchBillingInformation().catch(console.error)
  }, [])

  return (
    <Formik
      key={objectHash(defaultInitialValues)}
      initialValues={defaultInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreateTenant}
    >
      {({ handleSubmit, handleChange, setFieldTouched }) => {
        const change = (name, e) => {
          e.persist()
          handleChange(e)
          setFieldTouched(name, true, false)
        }

        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label={intl.formatMessage(messages.name)}
                  fullWidth
                  required
                  autoFocus
                  onChange={change.bind(null, 'name')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="billingReference"
                  label={intl.formatMessage({
                    id: 'createTenantForm.billingReference'
                  })}
                  fullWidth
                  required
                  disabled={true}
                  onChange={change.bind(null, 'billingReference')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="billingReferenceTag "
                  label={intl.formatMessage({
                    id: 'createTenantForm.billingReferenceTag'
                  })}
                  fullWidth
                  required
                  disabled={true}
                  onChange={change.bind(null, 'billingReferenceTag')}
                />
              </Grid>
              <Grid item xs={12}>
                <MikeButton
                  className={submitButtonStyle}
                  color="secondary"
                  variant="contained"
                  onClick={() => handleSubmit()}
                  type="button"
                >
                  {intl.formatMessage({
                    id: 'createTenantForm.createTenantButton'
                  })}
                </MikeButton>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateTenantForm
