import { call, put, takeEvery, select, fork } from 'redux-saga/effects'
import { intl } from '../index'
import {
  setAdminCredits,
  LOAD_ADMIN_CREDITS,
  loadingAdminCredits
} from '../actions/admin'

import {
  setTenants,
  LOAD_TENANTS,
  loadingTenants,
  CREATE_TENANT,
  creatingTenant,
  loadTenants,
  UPDATE_TENANT,
  UpdateTenantType,
  setCurrentTenantName
} from '../actions/tenant'

import { addError } from '../actions/errors'
import {
  getCredits,
  getUserTenants,
  postTenant,
  ICreateTenant,
  patchTenant,
  putBillingInformation
} from '../apis/admin'

import { getUser, getUserisBackOffice } from '../state'

import ITenant from '../model/ITenant'
import { notify } from '../helpers/saga'
import { ICreateTenantFormValues } from '../apis/admin'
import messages from '../shared/messages'

export default function* watchAdmin() {
  yield takeEvery(LOAD_TENANTS, fetchTenants)
  yield takeEvery(CREATE_TENANT, createTenant)
  yield takeEvery(UPDATE_TENANT, updateTenant)
  yield takeEvery(LOAD_ADMIN_CREDITS, fetchCredits)
}

export function* createTenant(action) {
  const isBackOffice = yield select(getUserisBackOffice)
  try {
    yield put(creatingTenant())
    const user = yield select(getUser)

    const formData = action.data as ICreateTenantFormValues
    const data = {
      name: formData.name,
      adminUserId: user.id,
      billingInformation: {
        billingReference: formData.billingReference,
        billingReferenceTag: formData.billingReferenceTag,
        billingReferenceType: formData.billingReferenceType
      }
    } as ICreateTenant
    const tenant = yield call(postTenant, data)

    yield fork(notify, `Site "${tenant.name}" was created!`)
  } catch (error) {
    yield put(addError(CREATE_TENANT, error))
  } finally {
    yield put(creatingTenant(false))
    yield put(loadTenants(isBackOffice))
  }
}

function* fetchCredits() {
  try {
    yield put(loadingAdminCredits())

    const response = yield call(getCredits)

    if (response && response.credits) {
      yield put(setAdminCredits(Math.round(response.credits)))
    }
  } catch (error) {
    // The credits are not available for this user, set them to 0
    yield put(setAdminCredits(0))
  } finally {
    yield put(loadingAdminCredits(false))
  }
}

function* fetchTenants(action) {
  const user = yield select(getUser)
  if (user) {
    const { canCreateTenants } = action
    yield put(loadingTenants())
    let tenants = new Array<ITenant>()
    try {
      const response = yield call(getUserTenants)

      if (response) {
        tenants = response
        // We need an active tenant to fetch and create content at root level
        if (tenants.length === 0) {
          if (!canCreateTenants) {
            yield put(
              addError(LOAD_TENANTS, intl.formatMessage(messages.noAccess))
            )
          }
        }
      }
      yield put(setTenants(tenants))
    } catch (error) {
      yield put(addError(LOAD_TENANTS, error))
    } finally {
      yield put(loadingTenants(false))
    }
  }
}

function* updateTenant(action: UpdateTenantType) {
  try {
    yield call(patchTenant, action.tenant)
    yield call(
      putBillingInformation,
      action.tenant.id,
      action.billingInformation
    )
    yield put(setCurrentTenantName(action.tenant.id, action.tenant.name))
  } catch (error) {
    yield put(addError(UPDATE_TENANT, error))
  }
}
