import {
  GridApi,
  GridColDef,
  GridSortItem,
  GridStateColDef,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

type SavedData = {
  columnsConfig: Partial<GridStateColDef>[]
  sortModel: GridSortItem[]
}

export interface UseSavedTableStateProps {
  tableKey: string
  defaultColumns: GridColDef[]
}

export function useSavedDataGridState(props: UseSavedTableStateProps) {
  const apiRef = useGridApiRef()
  const [columnsArray, setColumnsArray] = useState(props.defaultColumns)

  const saveState = (state: GridApi): void => {
    const allColumns = state.getAllColumns()
    const columnsConfig: Partial<GridStateColDef>[] = []

    for (const column of allColumns) {
      const { field, hide, width } = column
      columnsConfig.push({ field, hide, width })
    }

    const sortModel = state.getSortModel()

    localStorage.setItem(
      props.tableKey,
      JSON.stringify({ columnsConfig, sortModel })
    )
  }

  const debounceStateSave = useDebouncedCallback(() => {
    saveState(apiRef.current)
  }, 100)

  useEffect(() => {
    const applyChanges = (changes: SavedData): void => {
      const sortModel = changes?.sortModel ?? []
      const columnsConfig = changes?.columnsConfig ?? []

      if (columnsConfig) {
        const newColDefs: GridColDef[] = []

        for (const columnConfig of columnsConfig) {
          const colDef = columnsArray.find(c => c.field === columnConfig.field)
          if (colDef) {
            colDef.width = columnConfig.width
            colDef.hide = columnConfig.hide
            newColDefs.push(colDef)
          }
        }

        setColumnsArray(
          newColDefs.length === 0 ? props.defaultColumns : newColDefs
        )
      }

      if (sortModel) {
        apiRef.current.setSortModel(sortModel)
      }
    }

    let changes: SavedData = {
      columnsConfig: [],
      sortModel: []
    }

    try {
      const localState: string = localStorage.getItem(props.tableKey) ?? ''

      if (localState) {
        changes = JSON.parse(localState)
      }
    } catch (e) {
      console.error('Error parsing saved state', e)
    }

    applyChanges(changes)
  }, [props.tableKey, apiRef, props.defaultColumns, columnsArray])

  return { apiRef, debounceStateSave, columnsArray, setColumnsArray }
}
