import React, { useEffect, useState } from 'react'
import { css } from 'emotion'
import { useOidcAccessToken } from '@axa-fr/react-oidc'
import Typography from '@material-ui/core/Typography'
import theme from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { useTypedSelector } from '../../../reducers'
import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  makeStyles,
  FormControlLabel
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import BuyCreditsIcon from '../../icons/BuyCreditsIcon'
import Tooltip from '@material-ui/core/Tooltip'
import ConfirmationDialog from '../../Dialog/ConfirmationDialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { TextField } from '@material-ui/core'
import ReturnCreditsIcon from '../../icons/ReturnCreditsIcon'
import { ArrowBack, ArrowDropDown, Settings } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { CreditsStateEnum } from '../../../model/CreditsStateEnum'
import { useIntl } from 'react-intl'
import {
  callCreditAvailableGet,
  callCreditMovePut,
  callCreditTenantGet,
  callCreditUnassignedPost,
  callCreditReportPost
} from '../../../actions/credits'
import { getProject } from '../../../apis/admin'
import { ReportTypeEnum } from '../../../apis/reports'

const paddingStyle = css`
  padding: ${theme.spacing(0.7)}px;
`
const paddingStyle2 = css`
  padding: ${theme.spacing(2)}px;
`
const useStyles = makeStyles(theme => ({
  reportCheckWraps: {
    display: 'flex',
    flexDirection: 'column'
  },
  specialTypo: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  tableTopHeader: {
    display: 'flex',
    flexDirectoin: 'row',
    position: 'sticky',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    zIndex: 1,
    backgroundColor: 'white',
    border: '3px black',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  navSmallerPadding: {
    paddingBottom: theme.spacing(1)
  },
  dialogTitle: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(2)
  },
  dialogContent: {
    maxWidth: 378,
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.background.paper
  },
  iconButton: {
    marginRight: theme.spacing(1),
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center'
  },
  tableTopButtons: {
    display: 'flex',
    flexDirectoin: 'row',
    justifyContent: 'space-between',
    alignContent: 'flex-end',
    alignItems: 'center'
  },
  menuDropdown: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },

  popover: {
    maxWidth: '75vw'
  },
  content: {
    padding: theme.spacing(0),
    background: '#F8F8F8',
    width: 'max-content'
  },

  popHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    paddingRight: theme.spacing(6),
    width: 'max-width'
  },

  messageBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'space-between',
    width: '100%'
  }
}))

interface IProps {
  displayState: CreditsStateEnum
  disabled?: boolean
  columns?: any[]
  setColumns?: any
  onCreditsChange: () => void
}

const CreditsNav = (props: IProps) => {
  const history = useHistory()
  const classes = useStyles(props)
  const intl = useIntl()
  const { accessToken } = useOidcAccessToken()

  const dispatch = useDispatch()
  const tenantId =
    useTypedSelector(state => state['auth']?.user?.tenantId) || null
  const tenantName =
    useTypedSelector(state => state['auth']?.user?.tenantName) || '--'

  const [openAssignDialog, setOpenAssignDialog] = useState<boolean>(false)
  const [openReleaseDialog, setOpenReleaseDialog] = useState<boolean>(false)
  const [selectedReports, setSelectedReports] = useState<ReportTypeEnum[]>([])
  const [openReportDialog, setOpenReportDialog] = useState<boolean>(false)
  const [openBuyCreditsDialog, setOpenBuyCreditsDialog] = useState<boolean>(
    false
  )
  const [unassignedValue, setUnassignedValue] = useState<string>('--')

  const creditsUnassigned = useTypedSelector(
    state => state['credits'].creditUnassigned
  )
  const creditAvailable = useTypedSelector(
    state => state['credits'].creditAvailable
  )
  const [moveCredits, setMoveCredits] = useState<number | undefined>(undefined)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event.stopPropagation()
    setAnchorEl(null)
  }
  const handleClose2 = event => {
    event.stopPropagation()
    setAnchorEl2(null)
  }

  const valueLimitsTest = (bottomLimit, topLimit) => {
    const bl = parseFloat(bottomLimit)
    const ul = parseFloat(topLimit)

    return Boolean(moveCredits && (moveCredits < bl || moveCredits > ul))
  }

  const handleReportSelection = (reportName: ReportTypeEnum): void => {
    if (selectedReports.includes(reportName))
      setSelectedReports(
        selectedReports.filter(report => report !== reportName)
      )
    else setSelectedReports([...selectedReports, reportName])
  }

  const reloadCredits = () => {
    if (tenantId) {
      dispatch(callCreditTenantGet(tenantId))
      dispatch(callCreditAvailableGet(tenantId))
      getProject(tenantId).then(res => {
        let sendData = res.billingInformation?.billingReferenceTag
          ? res.billingInformation
          : {
              billingReferenceTag: '',
              billingReference: res.billingInformation?.billingReference,
              billingReferenceType:
                res.billingInformation?.billingReferenceType ?? ''
            }

        dispatch(callCreditUnassignedPost(sendData))
        props.onCreditsChange()
      })
    }
  }

  useEffect(() => {
    if (
      creditsUnassigned &&
      creditsUnassigned.data &&
      creditsUnassigned.data.length > 0
    ) {
      setUnassignedValue(
        creditsUnassigned.data.filter(item => item.status === 'Available')[0]
          .credits ?? '--'
      )
    } else {
      setUnassignedValue('--')
    }
  }, [creditsUnassigned])

  const getHeaderLabel = (state: CreditsStateEnum) => {
    switch (state) {
      case CreditsStateEnum.ALLOCATION:
        return intl.formatMessage({
          id: 'credits.creditsNav.allocation'
        })
      case CreditsStateEnum.AVAILABLE:
        return intl.formatMessage({
          id: 'credits.creditsNav.available'
        })
      case CreditsStateEnum.RESERVED:
        return intl.formatMessage({
          id: 'credits.creditsNav.reserved'
        })
      case CreditsStateEnum.SPENT:
        return intl.formatMessage({
          id: 'credits.creditsNav.spent'
        })
      default:
        return intl.formatMessage({
          id: 'credits.creditsNav.summary'
        })
    }
  }

  const getHeaderSubtitle = (): string => {
    return props.displayState !== CreditsStateEnum.ALLOCATION
      ? 'on ' + tenantName + ' site'
      : "from company's account"
  }

  const valueCheckedChange = (itemToChange, oldValue) => {
    const copyArray = props.columns?.map(item => {
      if (item.field === itemToChange.field) return { ...item, hide: !oldValue }
      else return item
    })

    props.setColumns(copyArray)
  }

  return (
    <>
      {/* //ASSIGN */}
      <ConfirmationDialog
        open={openAssignDialog}
        ok={intl.formatMessage({
          id: 'credits.creditsNav.assign'
        })}
        hideCancelButton={true}
        onOk={() => {
          const error = valueLimitsTest(0, unassignedValue)
          if (!error && moveCredits) {
            const data = {
              credits: moveCredits,
              sourceTenantId: null,
              targetTenantId: tenantId
            }
            dispatch(callCreditMovePut(data))
            reloadCredits()
            setMoveCredits(undefined)
            setOpenAssignDialog(false)
          }
        }}
        onCancel={() => {
          setMoveCredits(undefined)
          setOpenAssignDialog(false)
        }}
      >
        <MuiDialogContent className={classes.dialogContent}>
          <Typography variant="h3" className={classes.dialogTitle}>
            {intl.formatMessage({
              id: 'credits.creditsNav.assignCredits'
            })}
          </Typography>
          <Divider variant="fullWidth" light={false} />
          <Box mt={2}>
            <Typography variant="body2">
              {intl.formatMessage({
                id: 'credits.creditsNav.enterNumber'
              })}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body2">
              {intl.formatMessage({
                id: 'credits.creditsNav.currentBalace'
              })}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="h5">
              {Number(parseFloat(unassignedValue).toFixed(0)).toLocaleString(
                'fr'
              ) +
                intl.formatMessage({
                  id: 'credits.creditsNav.credits'
                })}
            </Typography>
          </Box>
          <Box mt={2}>
            <TextField
              error={valueLimitsTest(0, unassignedValue)}
              inputProps={{
                step: '0.1',
                lang: 'en-US'
              }}
              helperText={
                valueLimitsTest(0, unassignedValue) ? 'Incorrect ammount' : ''
              }
              style={{
                backgroundColor: '#aaaaaa4d',
                justifyContent: 'center'
              }}
              variant="standard"
              label={intl.formatMessage({
                id: 'credits.creditsNav.ammount'
              })}
              type="number"
              value={moveCredits || ''}
              onChange={e => setMoveCredits(parseFloat(e.target.value))}
              fullWidth
            />
          </Box>
        </MuiDialogContent>
      </ConfirmationDialog>
      {/* //RELEASE */}
      <ConfirmationDialog
        open={openReleaseDialog}
        ok={intl.formatMessage({
          id: 'credits.creditsNav.release'
        })}
        hideCancelButton={true}
        onOk={() => {
          const error = valueLimitsTest(0, creditAvailable)
          if (!error && moveCredits) {
            const data = {
              credits: moveCredits,
              sourceTenantId: tenantId,
              targetTenantId: null
            }
            dispatch(callCreditMovePut(data))
            reloadCredits()
            setMoveCredits(undefined)
            setOpenReleaseDialog(false)
          }
        }}
        onCancel={() => {
          setMoveCredits(undefined)
          setOpenReleaseDialog(false)
        }}
      >
        <MuiDialogContent className={classes.dialogContent}>
          <Typography variant="h3" className={classes.dialogTitle}>
            {intl.formatMessage({
              id: 'credits.creditsNav.returnCredits'
            })}
          </Typography>
          <Divider variant="fullWidth" light={false} />
          <Box mt={2}>
            <Typography variant="body2">
              {intl.formatMessage({
                id: 'credits.creditsNav.enterNumberReturn'
              })}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body2">
              {intl.formatMessage({
                id: 'credits.creditsNav.creditsExp'
              })}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body2">
              {intl.formatMessage({
                id: 'credits.creditsNav.currentBalanceAva'
              })}{' '}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="h5">
              {Number(parseFloat(creditAvailable).toFixed(0)).toLocaleString(
                'fr'
              ) +
                intl.formatMessage({
                  id: 'credits.creditsNav.credits'
                })}
            </Typography>
          </Box>
          <Box mt={2}>
            <TextField
              error={valueLimitsTest(0, creditAvailable)}
              inputProps={{
                step: '0.1',
                lang: 'en-US'
              }}
              helperText={
                valueLimitsTest(0, creditAvailable) ? 'Incorrect amount' : ''
              }
              style={{
                backgroundColor: '#aaaaaa4d',
                justifyContent: 'center'
              }}
              variant="standard"
              label={intl.formatMessage({
                id: 'credits.creditsNav.ammount'
              })}
              type="number"
              value={moveCredits || ''}
              onChange={e => setMoveCredits(parseFloat(e.target.value))}
              fullWidth
            />
          </Box>
        </MuiDialogContent>
      </ConfirmationDialog>
      {/* //BUY */}
      <ConfirmationDialog
        open={openBuyCreditsDialog}
        ok={intl.formatMessage({
          id: 'credits.creditsNav.continue'
        })}
        cancel={intl.formatMessage({
          id: 'credits.creditsNav.cancel'
        })}
        onOk={() => {
          setOpenBuyCreditsDialog(false)
          reloadCredits()
        }}
        onCancel={() => {
          setOpenBuyCreditsDialog(false)
        }}
      >
        <MuiDialogContent className={classes.dialogContent}>
          <Typography variant="h3" className={classes.dialogTitle}>
            {intl.formatMessage({
              id: 'credits.creditsNav.buyCredits'
            })}
          </Typography>
          <Divider variant="fullWidth" light={false} />
          <Box mt={2}>
            <Typography variant="body2">
              {intl.formatMessage({
                id: 'credits.creditsNav.youAreBuying'
              })}
            </Typography>
          </Box>
          <Box mt={2}>
            <TextField
              style={{
                backgroundColor: '#aaaaaa4d'
              }}
              variant="standard"
              label={intl.formatMessage({
                id: 'credits.creditsNav.ammount'
              })}
              fullWidth
            />
          </Box>
        </MuiDialogContent>
      </ConfirmationDialog>
      {/* //REPORT */}
      <ConfirmationDialog
        open={openReportDialog}
        ok={intl.formatMessage({
          id: 'credits.creditsNav.download'
        })}
        hideCancelButton={true}
        onOk={() => {
          selectedReports?.forEach(reportType => {
            dispatch(
              callCreditReportPost(
                tenantId as string,
                reportType as string,
                accessToken as string
              )
            )
          })
          setOpenReportDialog(false)
        }}
        onCancel={() => {
          setOpenReportDialog(false)
        }}
      >
        <MuiDialogContent className={classes.dialogContent}>
          <Typography variant="h3" className={classes.dialogTitle}>
            {intl.formatMessage({
              id: 'credits.creditsNav.export'
            })}{' '}
          </Typography>
          <Divider variant="fullWidth" light={false} />
          <Box mt={2}>
            <Typography variant="body2">
              {intl.formatMessage({
                id: 'credits.creditsNav.selectTheRange'
              })}
            </Typography>
          </Box>
          <Box mt={2} sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedReports.includes(ReportTypeEnum.CONSUMPTION)}
                />
              }
              label="Consumption"
              onChange={() => handleReportSelection(ReportTypeEnum.CONSUMPTION)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedReports.includes(ReportTypeEnum.CREDITS)}
                />
              }
              label="Credits"
              onChange={() => handleReportSelection(ReportTypeEnum.CREDITS)}
            />
          </Box>
        </MuiDialogContent>
      </ConfirmationDialog>
      {/* //SETTINGS */}
      <Popover
        className={classes.popover}
        open={Boolean(anchorEl2!)}
        anchorEl={anchorEl2}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className={classes.content}>
          <div className={classes.popHeader}>
            <Typography variant="h5" style={{ color: 'primary' }}>
              {'Displayed fields'}
            </Typography>
          </div>
          {props.columns &&
            props.columns?.map((item, index) => {
              return (
                <div key={index}>
                  {index !== 0 && <Divider />}
                  <div key={index} className={classes.messageBlock}>
                    <Checkbox
                      checked={!item.hide}
                      onChange={() => {
                        valueCheckedChange(item, item.hide)
                      }}
                      color="primary"
                    />
                    <Typography variant="body2" noWrap>
                      {item.headerName}
                    </Typography>
                  </div>
                </div>
              )
            })}
        </div>
      </Popover>
      <div className={classes.tableTopHeader}>
        {props.displayState === CreditsStateEnum.SUMMARY && (
          <Typography
            className={paddingStyle2}
            variant="h4"
            color="textPrimary"
          >
            {getHeaderLabel(props.displayState)}
          </Typography>
        )}

        {props.displayState !== CreditsStateEnum.SUMMARY && (
          <div className={classes.tableTopButtons}>
            <IconButton
              size={'small'}
              color="primary"
              style={{ marginTop: '10px', marginRight: '-10px' }}
              onClick={() => {
                history.push(`/site-info/credits`)
              }}
            >
              <ArrowBack />
            </IconButton>
            <div
              className={classes.specialTypo}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <div className={classes.menuDropdown}>
                <Typography variant="h4" color="textPrimary">
                  {getHeaderLabel(props.displayState)}
                </Typography>
                <ArrowDropDown style={{ paddingTop: '5px' }} />
              </div>
              <span style={{ fontSize: '0.8em', lineHeight: '0.8em' }}>
                {getHeaderSubtitle()}
              </span>
            </div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              style={{ marginTop: theme.spacing(7), padding: '0' }}
              MenuListProps={{
                disablePadding: true
              }}
              tabIndex={-1}
              open={Boolean(anchorEl!)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  history.push(`/site-info/credits`)
                }}
              >
                {' '}
                <Typography
                  className={paddingStyle}
                  variant="h5"
                  color="textPrimary"
                >
                  {intl.formatMessage({
                    id: 'credits.creditsNav.summary'
                  })}
                </Typography>
              </MenuItem>
              <Divider />

              {props.displayState !== CreditsStateEnum.AVAILABLE && (
                <MenuItem
                  onClick={() => {
                    history.push(`/site-info/credits-available`)
                  }}
                >
                  {' '}
                  <Typography
                    className={paddingStyle}
                    variant="body1"
                    color="textPrimary"
                  >
                    {intl.formatMessage({
                      id: 'credits.creditsNav.available'
                    })}{' '}
                  </Typography>
                </MenuItem>
              )}
              {props.displayState !== CreditsStateEnum.RESERVED && (
                <div>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      history.push(`/site-info/credits-reserved`)
                    }}
                  >
                    {' '}
                    <Typography
                      className={paddingStyle}
                      variant="body1"
                      color="textPrimary"
                    >
                      {intl.formatMessage({
                        id: 'credits.creditsNav.reserved'
                      })}
                    </Typography>
                  </MenuItem>
                </div>
              )}
              {props.displayState !== CreditsStateEnum.SPENT && (
                <div>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      history.push(`/site-info/credits-spent`)
                    }}
                  >
                    {' '}
                    <Typography
                      className={paddingStyle}
                      variant="body1"
                      color="textPrimary"
                    >
                      {intl.formatMessage({
                        id: 'credits.creditsNav.spent'
                      })}
                    </Typography>
                  </MenuItem>
                </div>
              )}
              {props.displayState !== CreditsStateEnum.ALLOCATION && (
                <div>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      history.push(`/site-info/credits-allocation`)
                    }}
                  >
                    {' '}
                    <Typography
                      className={paddingStyle}
                      variant="body1"
                      color="textPrimary"
                    >
                      {intl.formatMessage({
                        id: 'credits.creditsNav.allocation'
                      })}
                    </Typography>
                  </MenuItem>
                </div>
              )}
            </Menu>
          </div>
        )}

        <div className={classes.tableTopButtons}>
          {props.displayState !== CreditsStateEnum.RESERVED &&
            props.displayState !== CreditsStateEnum.SPENT && (
              <Tooltip
                title={intl.formatMessage({
                  id: 'credits.creditsNav.assignCreds'
                })}
              >
                <IconButton
                  color="secondary"
                  className={classes.iconButton}
                  size={'medium'}
                  onClick={() => {
                    setOpenAssignDialog(true)
                  }}
                >
                  <BuyCreditsIcon />
                </IconButton>
              </Tooltip>
            )}
          {props.displayState !== CreditsStateEnum.RESERVED &&
            props.displayState !== CreditsStateEnum.SPENT && (
              <Tooltip
                title={intl.formatMessage({
                  id: 'credits.creditsNav.releaseCreds'
                })}
              >
                <IconButton
                  color="secondary"
                  className={classes.iconButton}
                  size={'medium'}
                  onClick={() => {
                    setOpenReleaseDialog(true)
                  }}
                >
                  <ReturnCreditsIcon />
                </IconButton>
              </Tooltip>
            )}

          {/*<Tooltip title={'Download report'}>*/}
          {/*  <IconButton*/}
          {/*    color="secondary"*/}
          {/*    className={classes.iconButton}*/}
          {/*    size={'medium'}*/}
          {/*    onClick={() => {*/}
          {/*      setOpenReportDialog(true)*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <DownloadReportIcon />*/}
          {/*  </IconButton>*/}
          {/*</Tooltip>*/}

          {props.displayState !== CreditsStateEnum.SUMMARY && (
            <Tooltip
              title={intl.formatMessage({
                id: 'credits.creditsNav.settingsTool'
              })}
            >
              <IconButton
                color="secondary"
                className={classes.iconButton}
                size={'medium'}
                onClick={event => {
                  setAnchorEl2(event.currentTarget)
                }}
              >
                <Settings fill={theme.palette.secondary.main} />
              </IconButton>
            </Tooltip>
          )}
          {/*
          {props.displayState !== CreditsStateEnum.RESERVED &&
            props.displayState !== CreditsStateEnum.SPENT && (
              <Tooltip title={'Not available right now'}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    // setOpenBuyCreditsDialog(true)
                  }}
                >
                  {intl.formatMessage({
                    id: 'credits.creditsNav.buyCredits'
                  })}
                </Button>
              </Tooltip>
            )}
            */}
        </div>
      </div>
    </>
  )
}

export default CreditsNav
