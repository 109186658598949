import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import { iconStyle, useStyles } from './iconStyles'
import fetchConfig from '../../apis/config'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'

const LINKS_MAP = {
  dev0:
    'https://bswebapps-dev.dhigroup.com/DHI.WebApps.ITServices/Pages/Home.html?pn=11817725&env=dev',
  dev:
    'https://bswebapps-dev.dhigroup.com/DHI.WebApps.ITServices/Pages/Home.html?pn=11817725&env=dev',
  test:
    'https://bswebapps-test.dhigroup.com/DHI.WebApps.ITServices/Pages/Home.html?pn=11817725',
  prod:
    'https://bswebapps.dhigroup.com/DHI.WebApps.ITServices/Pages/Home.html?pn=11817725'
}

export function CreateInternalTenantButton() {
  const classes = useStyles()
  const [environment, setEnvironment] = useState<string>('')

  const link = environment ? LINKS_MAP[environment] : LINKS_MAP['prod']

  const getConfig = async () => {
    const config = await fetchConfig()

    setEnvironment(config.environment)
  }

  useEffect(() => {
    getConfig().then()
  }, [])

  // id: 'createTenantForm.howToCreateInternalSite' <- old translation id

  return (
    <Button
      className={classes.buttonSecondaryBorder}
      href={link}
      target="_blank"
    >
      <AddOutlinedIcon className={iconStyle} />
      <FormattedMessage id={'createTenantForm.createTenantButton'} />
    </Button>
  )
}
