import React, { useEffect, useState } from 'react'
import { css } from 'emotion'
import { cloneDeep } from 'lodash'
import SiteInfoNav from '../../SiteInfoNav'
import { useTypedSelector } from '../../../reducers'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { CreditsStateEnum } from '../../../model/CreditsStateEnum'
import CreditsNav from './CreditsNav'
import { callCreditDetailsExtendedPost } from '../../../actions/credits'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { Paper, Typography } from '@material-ui/core'
import { useSavedDataGridState } from '../../../hooks/use-saved-data-grid-state.hook'

const hoverStyle = () => css`
  &:hover {
    cursor: pointer;
  }
`
const creditsRender = ({ value }) => {
  return (
    <span>
      {new Intl.NumberFormat('fr', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value)}
    </span>
  )
}

const renderStringCell = ({ value }) => {
  if (value) return <span>{value}</span>
  else
    return (
      <Typography
        variant={'body2'}
        style={{ fontWeight: '200', fontStyle: 'italic' }}
        color="textPrimary"
      >
        Execution removed
      </Typography>
    )
}

const dateTimeRender = ({ value }) => moment(value).format('DD MMM YYYY hh:mm')
const salesOrderIdRender = ({ value }) => <span>{value}</span>

const CreditsSpent = () => {
  const intl = useIntl()
  const tenantId = useTypedSelector(state => state['auth']?.user?.tenantId)
  const creditLoading = useTypedSelector(state => state['credits'].loadingState)
  const creditDetailsExt = useTypedSelector(
    state => state['credits'].creditDetailsExt
  )

  const [currentPage, setCurrentPage] = useState<number>(0)
  const [currentRows, setCurrentRows] = useState<number>(10)
  const [entries, setEntries] = useState<any>()

  const defaultColumns: GridColDef[] = [
    {
      field: 'credits',
      headerName: intl.formatMessage({
        id: 'credits.columns.ammount'
      }),
      cellClassName: hoverStyle,
      renderCell: params => creditsRender(params),
      sortable: true,
      sortComparator: (v1, v2) => String(v1).localeCompare(String(v2)),
      width: 140
    },
    {
      field: 'startedAt',
      headerName: intl.formatMessage({
        id: 'credits.columns.executionStarted'
      }),
      cellClassName: hoverStyle,
      renderCell: params => dateTimeRender(params),
      sortable: true,
      sortComparator: (v1, v2) => String(v1).localeCompare(String(v2)),
      width: 215
    },
    {
      field: 'finishedAt',
      headerName: intl.formatMessage({
        id: 'credits.columns.executionFinished'
      }),
      hide: true,
      cellClassName: hoverStyle,
      renderCell: params => dateTimeRender(params),
      sortable: true,
      sortComparator: (v1, v2) => String(v1).localeCompare(String(v2)),
      width: 215
    },
    {
      field: 'executionTime',
      headerName: intl.formatMessage({
        id: 'credits.columns.executionTime'
      }),
      cellClassName: hoverStyle,
      renderCell: params => params.row.executionTime ?? 'Execution deleted',
      valueGetter: params => [
        params.row.executionTime,
        params.row.startedAt,
        params.row.finishedAt
      ],
      sortable: true,
      sortComparator: (v1, v2) => {
        const executionTimeLeft = v1![0]
        const executionTimeRight = v2![0]

        const startedAtLeft = new Date(v1![1]).getTime()
        const startedAtRight = new Date(v2![1]).getTime()

        const finishedAtLeft = new Date(v1![2]).getTime()
        const finishedAtRight = new Date(v2![2]).getTime()

        if (
          startedAtLeft &&
          startedAtRight &&
          finishedAtLeft &&
          finishedAtRight
        ) {
          const executionTimeLeftNum = finishedAtLeft - startedAtLeft
          const executionTimeRightNum = finishedAtRight - startedAtRight

          return executionTimeLeftNum - executionTimeRightNum
        } else {
          return String(executionTimeLeft).localeCompare(
            String(executionTimeRight)
          )
        }
      },
      width: 175
    },
    {
      field: 'operationId',
      headerName: intl.formatMessage({
        id: 'credits.columns.executionId'
      }),
      cellClassName: hoverStyle,
      renderCell: params => renderStringCell(params),
      sortable: true,
      sortComparator: (v1, v2) => String(v1).localeCompare(String(v2)),
      width: 300
    },
    {
      field: 'projectName',
      headerName: intl.formatMessage({
        id: 'credits.columns.projectName'
      }),
      cellClassName: hoverStyle,
      renderCell: params => renderStringCell(params),
      sortable: true,
      sortComparator: (v1, v2) => String(v1).localeCompare(String(v2)),
      width: 430
    },
    {
      field: 'projectId',
      headerName: intl.formatMessage({
        id: 'credits.columns.projectNo'
      }),
      hide: true,
      cellClassName: hoverStyle,
      renderCell: params => renderStringCell(params),
      sortable: true,
      sortComparator: (v1, v2) => String(v1).localeCompare(String(v2)),
      width: 290
    },
    {
      field: 'vm',
      headerName: intl.formatMessage({
        id: 'credits.columns.machine'
      }),
      cellClassName: hoverStyle,
      renderCell: params => renderStringCell(params),
      sortable: true,
      sortComparator: (v1, v2) => String(v1).localeCompare(String(v2)),
      width: 150
    },
    {
      field: 'poolType',
      headerName: intl.formatMessage({
        id: 'credits.columns.poolType'
      }),
      cellClassName: hoverStyle,
      renderCell: params => renderStringCell(params),
      sortable: true,
      sortComparator: (v1, v2) => String(v1).localeCompare(String(v2)),
      width: 150
    },
    {
      field: 'salesOrderId',
      headerName: intl.formatMessage({
        id: 'credits.columns.salesOrderId'
      }),
      hide: true,
      cellClassName: hoverStyle,
      renderCell: params => salesOrderIdRender(params),
      sortable: true,
      sortComparator: (v1, v2) => String(v1).localeCompare(String(v2)),
      width: 150
    }
  ]

  const {
    apiRef,
    debounceStateSave,
    columnsArray,
    setColumnsArray
  } = useSavedDataGridState({
    tableKey: 'credits-spent-settings',
    defaultColumns
  })

  // const [columnsArray, setColumnsArray] = useState<any[]>(defaultColumns)
  const dispatch = useDispatch()

  const onChangePage = page => {
    const properPage = Math.floor(page)

    const dataOffset = properPage * currentRows
    setCurrentPage(properPage)

    const data = {
      statusFilter: ['Spent'], //, 'Spent', 'Reserved', 'Repaid'
      offset: dataOffset,
      limit: currentRows,
      tenantId: tenantId ?? '--'
    }

    dispatch(callCreditDetailsExtendedPost(data))
  }

  const onChangeRowsPerPage = rowsPerPage => {
    const dataOffset = currentPage * currentRows
    setCurrentRows(rowsPerPage)
    setCurrentPage(dataOffset / rowsPerPage)

    const data = {
      statusFilter: ['Spent'], //, 'Spent', 'Reserved', 'Repaid'
      offset: dataOffset,
      limit: rowsPerPage,
      tenantId: tenantId ?? '--'
    }

    dispatch(callCreditDetailsExtendedPost(data))
  }

  useEffect(() => {
    const data = {
      statusFilter: ['Spent'], //, 'Spent', 'Reserved', 'Repaid'
      offset: 0,
      limit: currentRows,
      tenantId: tenantId ?? '--'
    }

    dispatch(callCreditDetailsExtendedPost(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tenantId])

  useEffect(() => {
    setEntries(cloneDeep(creditDetailsExt))
  }, [creditDetailsExt])

  return (
    <>
      <SiteInfoNav />
      <CreditsNav
        displayState={CreditsStateEnum.SPENT}
        columns={columnsArray}
        setColumns={setColumnsArray}
        onCreditsChange={() => {}}
      />
      <div style={{ marginTop: '1em' }} />
      <Paper>
        <DataGridPro
          autoHeight
          apiRef={apiRef}
          rows={entries?.data?.map(item => ({ ...item, id: uuidv4() })) ?? []}
          columns={columnsArray}
          pagination
          page={currentPage}
          pageSize={creditDetailsExt?.limit ?? 0}
          rowCount={creditDetailsExt?.totalCount ?? 0}
          paginationMode="server"
          getRowId={item => item.id}
          loading={creditLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onPageSizeChange={onChangeRowsPerPage}
          onPageChange={onChangePage}
          onStateChange={debounceStateSave}
        />
      </Paper>
    </>
  )
}

export default CreditsSpent
