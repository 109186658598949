import React, { useEffect, useState } from 'react'
import { css } from 'emotion'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash'

import { CreditsStateEnum } from '../../../model/CreditsStateEnum'

import SiteInfoNav from '../../SiteInfoNav'
import { useTypedSelector } from '../../../reducers'
import { callCreditDetailsExtendedPost } from '../../../actions/credits'
import { WarningSharp } from '@material-ui/icons'
import { Paper, Typography } from '@material-ui/core'
import UTCDateTime from '../../DataTable/renders/UTCDateTime'
import CreditsNav from './CreditsNav'
import { useSavedDataGridState } from '../../../hooks/use-saved-data-grid-state.hook'

const hoverStyle = () => css`
  &:hover {
    cursor: pointer;
  }
`
const validateDate = (state, transparent = false) => {
  if (state === 2) return 'red'
  if (state === 1) return 'orange'
  return transparent ? 'transparent' : 'inherit'
}

const validityRender = ({ value }) => (
  <div
    style={{
      marginLeft: '-2em',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end'
    }}
  >
    <WarningSharp style={{ color: validateDate(value[1], true) }} />
    <Typography
      variant="body2"
      style={{ color: validateDate(value[1], false), marginLeft: '0.5em' }}
    >
      {value[0]}
    </Typography>
  </div>
)

const dateTimeRender = ({ value }) => <UTCDateTime date={value} />

const creditsRender = ({ value }) => {
  return (
    <span>
      {new Intl.NumberFormat('fr', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value)}
    </span>
  )
}

const salesOrderIdRender = ({ value }) => <span>{value}</span>

const CreditsAvailable = () => {
  const intl = useIntl()
  const tenantId = useTypedSelector(state => state.auth?.user?.tenantId)
  const creditLoading = useTypedSelector(state => state.credits.loadingState)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [currentRows, setCurrentRows] = useState<number>(10)
  const [entries, setEntries] = useState<any>()
  const creditDetailsExt = useTypedSelector(
    state => state.credits.creditDetailsExt
  )

  const defaultColumns: GridColDef[] = [
    {
      field: 'credits',
      headerName: intl.formatMessage({
        id: 'credits.columns.ammount'
      }),
      hide: false,
      cellClassName: hoverStyle,
      renderCell: params => creditsRender(params),
      width: 635
    },
    {
      field: 'expiresAt',
      headerName: intl.formatMessage({
        id: 'credits.columns.expiredBy'
      }),
      hide: false,
      cellClassName: hoverStyle,
      renderCell: params => dateTimeRender(params),
      width: 635
    },
    {
      field: 'validity',
      headerName: intl.formatMessage({
        id: 'credits.columns.timeLeft'
      }),
      hide: false,
      cellClassName: hoverStyle,
      renderCell: params => validityRender(params),
      width: 635,
      valueGetter: params => {
        // provides alternative value for sorting
        // because MUI does not understand sorting with array cell
        const expiresAtTimeValue = new Date(params.row.expiresAt).getTime()

        return [params.row.validity[0], expiresAtTimeValue]
      },
      sortComparator: (v1, v2) => (v1![1] as number) - (v2![1] as number)
    },
    {
      field: 'salesOrderId',
      headerName: intl.formatMessage({
        id: 'credits.columns.salesOrderId'
      }),
      hide: false,
      cellClassName: hoverStyle,
      renderCell: params => salesOrderIdRender(params),
      width: 635
    }
  ]

  const {
    apiRef,
    debounceStateSave,
    columnsArray,
    setColumnsArray
  } = useSavedDataGridState({
    tableKey: 'credits-available-settings',
    defaultColumns
  })

  const dispatch = useDispatch()

  useEffect(() => {
    const data = {
      statusFilter: ['Available'], //, 'Spent', 'Reserved', 'Repaid'
      offset: 0,
      limit: 25,
      tenantId: tenantId ?? '--'
    }
    dispatch(callCreditDetailsExtendedPost(data))
  }, [dispatch, tenantId])

  const onChangePage = page => {
    const properPage = Math.floor(page)

    const dataOffset = properPage * currentRows
    setCurrentPage(properPage)

    const data = {
      statusFilter: ['Available'], //, 'Spent', 'Reserved', 'Repaid'
      offset: dataOffset,
      limit: currentRows,
      tenantId: tenantId ?? '--'
    }

    dispatch(callCreditDetailsExtendedPost(data))
  }

  const onChangeRowsPerPage = rowsPerPage => {
    setCurrentRows(rowsPerPage)
    const dataOffset = currentPage * currentRows
    setCurrentPage(dataOffset / rowsPerPage)
    const data = {
      statusFilter: ['Available'], //, 'Spent', 'Reserved', 'Repaid'
      offset: dataOffset,
      limit: rowsPerPage,
      tenantId: tenantId ?? '--'
    }
    dispatch(callCreditDetailsExtendedPost(data))
  }

  /**
   * reload on change in CreditsNav
   */
  function onCreditsChange() {
    const dataOffset = currentPage * currentRows
    const data = {
      statusFilter: ['Available'], //, 'Spent', 'Reserved', 'Repaid'
      offset: dataOffset,
      limit: creditDetailsExt?.limit,
      tenantId: tenantId ?? '--'
    }
    dispatch(callCreditDetailsExtendedPost(data))
  }

  useEffect(() => {
    setEntries(cloneDeep(creditDetailsExt))
  }, [creditDetailsExt])

  return (
    <>
      <SiteInfoNav />
      <CreditsNav
        displayState={CreditsStateEnum.AVAILABLE}
        columns={columnsArray}
        setColumns={setColumnsArray}
        onCreditsChange={onCreditsChange}
      />
      <div style={{ marginTop: '1em' }} />
      <Paper>
        <DataGridPro
          apiRef={apiRef}
          autoHeight
          rows={entries?.data?.map(item => ({ ...item, id: uuidv4() })) ?? []}
          columns={columnsArray}
          pagination
          page={currentPage}
          pageSize={creditDetailsExt?.limit ?? 0}
          rowCount={creditDetailsExt?.totalCount ?? 0}
          paginationMode="server"
          getRowId={item => item.id}
          loading={creditLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onPageSizeChange={onChangeRowsPerPage}
          onPageChange={onChangePage}
          onStateChange={debounceStateSave}
        />
      </Paper>
    </>
  )
}

export default CreditsAvailable
