import find from 'lodash/fp/find'
import filter from 'lodash/fp/filter'

export const getLoginConfig = state => {
  const { clientId, tenantId, resourceId, sessionId } = state.ui.config
  return { clientId, tenantId, resourceId, sessionId }
}

export const getAllCoordinateSystems = state =>
  state.coordinateSystems.allCoordinateSystems

export const getSelectedCoordinateSystems = state =>
  state.coordinateSystems.selectedCoordinateSystems

export const getCoordinateSystemsLoading = state =>
  state.coordinateSystems.loadingCoordinateSystems

export const getSearchCoordinateSystemsById = state =>
  state.coordinateSystems.searchCoordinateSystemsById

export const getIntlConfig = state => state.ui.config.intl

export const getAdminCredits = state => state.admin.credits

export const getTenants = state => state.tenant.tenants

export const getLoadingTenants = state => state.tenant.loadingTenants

export const getUsers = state => state.users.users

export const getLoadingUsers = state => state.users.loadingUsers

export const getAdminLoadingCredits = state => state.admin.loadingCredits

export const getUserIsAdmin = state => state.admin.isAdmin
export const getUserisBackOffice = state => state.admin.isBackOffice

export const getSupportConfig = state => state.ui.config.support

export const getTutorial = state => state.ui.config.tutorial

export const getCurrentLanguage = state => state.intl.locale

export const getReadersToHide = state => state.ui.config.readersToHide

export const getUser = state => {
  if (process.env.REACT_APP__DHI_TARGET_ENV === 'localhost') {
    return {
      userName: 'adam.amur.dhi1@outlook.com',
      profile: {
        id: '862281a7-2e66-4c76-af8d-29c82c723b4b',
        tenantId: '4c2ebde0-11c8-45fa-9a22-3be5b8203a88',
        name: 'Adam Amur',
        initials: 'AA',
        email: 'adam.amur.dhi1@outlook.com',
        token:
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImllX3FXQ1hoWHh0MXpJRXN1NGM3YWNRVkduNCIsImtpZCI6ImllX3FXQ1hoWHh0MXpJRXN1NGM3YWNRVkduNCJ9.eyJhdWQiOiIyZWJmOGFlOS1mMzc5LTQxNTctYmFjZi02Y2U4MWVjMjE2NDYiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC80YzJlYmRlMC0xMWM4LTQ1ZmEtOWEyMi0zYmU1YjgyMDNhODgvIiwiaWF0IjoxNTY2NDYyNzI0LCJuYmYiOjE1NjY0NjI3MjQsImV4cCI6MTU2NjQ2NjYyNCwiYWNyIjoiMSIsImFpbyI6IkFWUUFxLzhNQUFBQW5jdlp0YjJXWHd5eERCN2pWRVcwcW8vSXNoTmJMTUpva1JDK0JLZ2NaQnkwcEJDbGk4S1I1bFNDMWxJU1RrSkxoZnMxVGtMYUFNOVY2dERXYzE5UXRBR1FsR2RTRTFnQkordUs4d01sM044PSIsImFtciI6WyJwd2QiXSwiYXBwaWQiOiIyMTllM2MyMi1lYmRiLTRjZDAtYmZkNi1lMmJjNjU3MGRjN2EiLCJhcHBpZGFjciI6IjEiLCJlbWFpbCI6ImFkYW0uYW11ci5kaGkxQG91dGxvb2suY29tIiwiZmFtaWx5X25hbWUiOiJBbXVyIiwiZ2l2ZW5fbmFtZSI6IkFkYW0iLCJpZHAiOiJsaXZlLmNvbSIsImlwYWRkciI6IjE5My44Ni4xOS45OSIsIm5hbWUiOiJBZGFtIEFtdXIiLCJvaWQiOiJhYmExYTNkZS1lYWRkLTQ5MzctOTgwZi02OWRmZGM5ZmFkMzUiLCJzY3AiOiJ1c2VyX2ltcGVyc29uYXRpb24iLCJzdWIiOiJvMDVpTzZOeUtqMU9kaEZUOXpNRGJLM18zdGZFR2RPVzg0ZlZmNHNzZzRjIiwidGlkIjoiNGMyZWJkZTAtMTFjOC00NWZhLTlhMjItM2JlNWI4MjAzYTg4IiwidW5pcXVlX25hbWUiOiJsaXZlLmNvbSNhZGFtLmFtdXIuZGhpMUBvdXRsb29rLmNvbSIsInV0aSI6IjQydzFTbUtCREVlZ0d5TE5BX2o2QUEiLCJ2ZXIiOiIxLjAifQ.e_54Gudo_giwlsDXC65g252NDALXisTBMUf4nuDzp2EtlMRnfVIlmJjV1vidBEQeAwEHRLOR6K_Ipw-mC4YCHKKaUfCVHmR166D1wsJwjc9DLfYL18Ijg1nC4bOGTRYX5V9Te7CIDmG3PnBjHinGr58PXg623WPlVc9HeiP_f-GJfuNGCaUYgFmhKY61bTlUPjZg39cLvKgWFzhpVvqEbEKHPTfPEZZFbCo6PzYzwpfvHASo65A99LvSzFWYJ1p_g8ozhSQiHh2waQcdNfx00JajaB7aX56MbfGH6gs62xdXTEx8LQ8_AqhZWkaP4GEpBSly1fpaQV53M9CN-iyTOw'
      }
    }
  } else {
    return state.auth.user
  }
}

export const getGroups = state => state.groups.groups

export const getErrors = state => state.errors

export const getPopupReminderIsOpen = state => state.ui.popupReminderIsOpen
export const getSurpressPopupReminder = state => state.ui.surpressPopupReminder

export const getOpenAppStatus = state => state.ui.openAppStatus
export const getAppStatus = state => state.ui.appStatus

export const getLoadingApplicationsNotifications = state =>
  state.applications.loading
export const getApplicationsNotifications = state => state.applications.data

export const getLoadingApplications = state => state.applications.loadingApps
export const getApplications = state => state.applications.apps

export const getLoadingProjects = state => state.projects.loading
export const getProjects = state => state.projects.data

export const getDeletingProjects = state => state.projects.deleting

export const getProjectsPagination = state => state.projects.page

export const getProjectsRowsPerPage = state => state.projects.rowsPerPage

export const getProjectsSortBy = state => state.projects.sortBy

export const getProjectsSortOrder = state => state.projects.sortOrder

export const getProjectsTotalCount = state => state.projects.totalCount

export const getProjectsNamePrefix = state => state.projects.namePrefix

export const getProjectsOnlyMyOnes = state => state.projects.my

export const getProjectPagination = state => state.project.pagination.page

export const getProjectRowsPerPage = state =>
  state.project.pagination.rowsPerPage

export const getUsersRowsPerPage = state => state.users.rowsPerPage

export const getProjectPath = state => state.project.path.data

export const getLoadingProjectPath = state => state.project.path.loading

export const getLoadingRecycleBinContent = state =>
  state.recycleBin.content.loading
export const getRecycleBinContent = state => state.recycleBin.content.data

export const getLoadingRecycleBinItemsDeletedByMe = state =>
  state.recycleBin.itemsDeletedByMe.loading
export const getRecycleBinItemsDeletedByMe = state =>
  state.recycleBin.itemsDeletedByMe.data

export const getRecycleBinItemsDeletedByMeOffset = state =>
  state.recycleBin.itemsDeletedByMe.offset

export const getRecycleBinItemsDeletedByMeLimit = state =>
  state.recycleBin.itemsDeletedByMe.limit

export const getRecycleBinItemsDeletedByMeSortBy = state =>
  state.recycleBin.itemsDeletedByMe.sortBy

export const getRecycleBinItemsDeletedByMeSortOrder = state =>
  state.recycleBin.itemsDeletedByMe.sortOrder

export const getRecycleBinItemsDeletedByMeTotalCount = state =>
  state.recycleBin.itemsDeletedByMe.totalCount

export const getRestoringRecycleBinItemsDeletedByMe = state =>
  state.recycleBin.itemsDeletedByMe.restoring

export const getLoadingRecycleBinProjectDetails = state =>
  state.recycleBin.project.details.loading
export const getRecycleBinProjectDetails = state =>
  state.recycleBin.project.details.data

export const getLoadingRecycleBinProjectDatasets = state =>
  state.recycleBin.project.datasets.loading
export const getRecycleBinProjectDatasets = state =>
  state.recycleBin.project.datasets.data

export const getLoadingProjectDetails = state => state.project.details.loading
export const getProjectDetails = state => state.project.details.data

export const getProjectCapabilities = state =>
  state.project.details.capabilities

export const getLoadingProjectMembers = state => state.project.members.loading
export const getProjectMembers = state => state.project.members.data

export const getProject = state => state.project

export const getLoadingProjectDatasets = state => state.project.datasets.loading
export const getProjectDatasets = state => state.project.datasets.data
export const getProjectContent = state => state.project.projectContent.data
export const getProjectContentTotalCount = state =>
  state.project.projectContent.totalCount
export const getProjectContentPagination = state =>
  state.project.pagination.page
export const getProjectContentRowsPerPage = state =>
  state.project.pagination.rowsPerPage
export const getProjectContentNamePrefix = state =>
  state.project.projectContent.namePrefix
export const getProjectContentSortBy = state =>
  state.project.projectContent.sortBy
export const getProjectContentSortOrder = state =>
  state.project.projectContent.sortOrder

export const getLoadingProjectSubprojects = state =>
  state.project.subprojects.loading
export const getProjectSubprojects = state => state.project.subprojects.data

export const getLoadingProjectSubprojectsTree = state =>
  state.project.subprojectsForTree.loading
export const getProjectSubprojectsTree = state =>
  state.project.subprojectsForTree.data
export const getProjectTree = state => state.project.tree.data

export const getProjectInitialTree = state => state.project.initialtree.data
export const getLoadingProjectInitialTree = state =>
  state.project.initialtree.loading

export const getLoadingProjectParent = state => state.project.parent.loading
export const getProjectParent = state => state.project.parent.data

export const getLoadingProjectRoot = state => state.project.root.loading
export const getProjectRoot = state => state.project.root.data

export const getDatasetReaders = state => state.datasetReaders.data
export const getDatasetDedicatedReaders = state =>
  state.datasetReaders.dedicatedReaders
export const getDatasetFileReaders = state => state.datasetReaders.fileReaders
export const getLoadingDatasetReaders = state => state.datasetReaders.loading

export const getDatasetWriters = state => state.datasetWriters.data
export const getDatasetDedicatedWriters = state =>
  state.datasetWriters.dedicated
export const getDatasetFileWriters = state => state.datasetWriters.fileWriters
export const getLoadingDatasetWriters = state => state.datasetWriters.loading

export const getUploads = state => state.uploads
export const getImports = state => state.imports
export const getImport = (state, id) => find({ id })(state.imports)
export const getConvert = (state, id) => find({ id })(state.converts)

export const getExports = state => state.exports

export const getProgressItems = state => state.progressItems

export const getProgressSnackVisibility = state => state.ui.progressSnackVisible

export const getNotification = state => state.ui.notification

export const getLinkToProject = state => state.ui.linkToProject

export const getLinkNotification = state => state.ui.linkNotification

export const getLoadingExporters = state => state.datasetExporters.loading

export const getExporters = state => state.datasetExporters.data
export const getDatasetTypeExporters = (state, props) =>
  filter(exp => exp.datasetTypes.includes(props.datasetType))(
    getExporters(state)
  )

export const getCustomerUsers = state => state.customerUsers.data
export const getCreditsTenantId = state => state.creditsTenantId.data
export const getLoadingCustomerUsers = state => state.customerUsers.loading

export const getTenantFeatures = state => state.features.features
